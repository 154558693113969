<template>
  <btn
    component-name="btn-tab"
    :use-router-link-class="true"
    :title="$attrs.title"
    :action="$attrs.action || undefined"
    class="relative top-0 left-0 inline-flex justify-center items-center gap-xs min-w-min text-button/none whitespace-nowrap leading-none after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:bg-current after:origin-bottom after:scale-y-0 hover:after:scale-y-100 after:transition-transform after:duration-300 after:ease-in-out [&.router-link-exact-active]:after:!scale-y-100"
    :class="{
      'after:!scale-y-100': $attrs.pressed,
    }"
    @mouseenter="$device.isDesktop ? tpl_hoverIcon?.animate() : () => {}"
  >
    <hover-icon
      ref="tpl_hoverIcon"
      v-if="$attrs.icon"
      :icon="$attrs.icon"
      size="big"
    />

    <div
      class="whitespace-nowrap subpixel-antialiased"
      v-html="$attrs.title"
    ></div>
  </btn>
</template>

<script setup>
const tpl_hoverIcon = templateRef("tpl_hoverIcon");
</script>
